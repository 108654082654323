import React from "react"
import SEO from '../components/seo';
import Layout from '../containers/layout/layout'
import Banner from '../containers/contact/banner'
import ContactFormSection from '../containers/contact/contact-form'
import ContactInfoSection from '../containers/contact/contact-info'

export default () => (
    <Layout Layout headerLayout={2} >
        <SEO title="Контакты"/>
		<Banner/>
        <ContactFormSection/>
        <ContactInfoSection/>
    </Layout>
)
